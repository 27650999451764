<template>
  <v-footer
    dark
    padless
  >
    <v-card
      flat
      tile
      class="white--text text-center"
      color="#193154"
      width="8000"
    >
      <v-card-text>
        <v-btn
          v-for="(icon,n) in icons"
          :key="n"
          class="mx-4 white--text"
          icon
          large
          exact-path
          :to="icon.url"
        >
          <v-icon size="35px">
            {{ icon.name }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-img 
      src="@/assets/logo-enst-air-blanc.png"
      class="mx-auto mb-5"
      max-width="300"
      href="/"
      >
      </v-img>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — <strong>ENST'Air</strong>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
    data() {
        return {
            icons: [
                {
                  name:'mdi-facebook',
                  url: '/facebook'
                },
                {
                  name: 'mdi-twitter',
                  url: '/twitter'
                },
                {
                  name:'mdi-linkedin',
                  url: '/linkedin'
                },
                {
                  name:'mdi-instagram',
                  url: '/instagram'
                }
            ]
        }
    }

}
</script>

<style>

</style>