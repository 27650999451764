import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Accueil',
    component: () => import('../views/Accueil.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/galerie',
    name: 'Galerie',
    component: () => import( '../views/Galerie.vue')
  },
  {
    path: '/projets_en_cours/antares',
    name: 'Antares',
    component: () => import( '../views/Antares.vue')
  },
  {
    path: '/projets_en_cours/perseus',
    name: 'Perseus',
    component: () => import( '../views/Perseus.vue')
  },
  {
    path: '/equipe',
    name: 'Equipe',
    component: () => import( '../views/Equipe.vue')
  },
  {
    path: '/facebook',
    name: 'Facebook',
    beforeEnter() {
      // Put the full page url including the protocol http(s) below
      window.location = "https://www.facebook.com/enstair"
    }
  },
  {
    path: '/twitter',
    name: 'Twitter',
    beforeEnter() {
      // Put the full page url including the protocol http(s) below
      window.location = "https://twitter.com/enst_air"
    }
  },
  {
    path: '/instagram',
    name: 'Instagram',
    beforeEnter() {
      // Put the full page url including the protocol http(s) below
      window.location = "https://www.instagram.com/enstairaero/"
    }
  },
  {
    path: '/linkedin',
    name: 'LinkedIn',
    beforeEnter() {
      // Put the full page url including the protocol http(s) below
      window.location = "https://www.linkedin.com/company/enst'air/about/"
    }
  },
  {
    path: '/activites',
    name: 'Activites',
    component: () => import( '../views/Activites.vue')
  },
  {
    path: '/bapteme2023',
    name: 'Bapteme',
    beforeEnter() {
      // Put the full page url including the protocol http(s) below
      window.location = "https://www.google.com/"
    }
  },
    {
      path: "*",
      name: "404",
      component: () => import( '../views/Error404.vue')
    }
    
  

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    document.getElementById('app').scrollIntoView();
},
  routes
})

export default router
