<template>
  <v-app>
    <NavBar/>
    <v-main>
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>

    
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
export default {
  name: 'App',

  components: {
    NavBar,
    Footer
    
  },

  data: () => ({
    //
  }),
};
</script>

<style>
.fade-enter {
  opacity: 0;
  transform: translateY(40px);
}
.fade-enter-active{
  
  transition: all 2s
}
</style>
