<template>
  <v-app-bar app elevate-on-scroll color="white" fade-img-on-scroll>
    <v-toolbar-title>
      <v-img src="@/assets/logo-enst-air.png" max-width="130"></v-img>
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <!--
    <v-btn
      v-for="link in links"
      :key="`${link.label}-header-link`"
      rounded
      text
      color="#193154"
      class="mx-2 btn"
      :to="link.url"
    >
      <v-icon left> {{ link.icon }} </v-icon>
      {{ link.label }}
    </v-btn>
    -->
    <!-- -->
    <v-menu
      open-on-click
      transition="slide-y-transition"
      bottom
      class="menu"
      offset-y
      v-for="link in links"
      :key="`${link.label}-header-link`"
      rounded="b-lg"
      :disabled="haveMultiItems(link.items)"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          :class="['mx-2', haveMultiItems(link.items) ? 'rounded-pill' : '']"
          color="#193154"
          dark
          text
          v-bind="attrs"
          v-on="on"
          :to="haveMultiItems(link.items) ? link.url : '' "
        >
          <v-icon left> {{ link.icon }} </v-icon>
          {{ link.label }}
          <v-icon light right v-if="!haveMultiItems(link.items)">
            mdi-chevron-down
          </v-icon>
        </v-btn>
      </template>

      <v-list v-for="(item, index) in link.items" :key="index">
        <div v-if="!haveMultiItems(item.subitems)">
          <!--
          <v-list-group open-on-hover>
            <template v-slot:activator>
              <v-list-item-title>{{item.label}}</v-list-item-title>
            </template>

            <v-list-group
              :value="true"
              no-action
              sub-group
            >
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>Admin</v-list-item-title>
              </v-list-item-content>
            </template>
            </v-list-group>
          </v-list-group>
          -->

          <v-menu open-on-hover transition="slide-x-transition" right offset-x>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" :to="item.url">
                {{ item.label }}
                <v-icon right>
                  mdi-chevron-right
                </v-icon>
              </v-list-item>
            </template>
            <v-list v-for="(subitem, index_sub) in item.subitems" :key="index_sub">
              <v-list-item :to="subitem.url">
                {{subitem.label}}
              </v-list-item>
            </v-list>
          </v-menu>
        </div>

        <div v-else>
          <v-list-item :to="item.url">
            <v-list-item-title>{{ item.label }}</v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>

<script>
export default {
  name: "NavBar",
  data() {
    return {
      links: [
        {
          label: "Accueil",
          url: "/",
          icon: "mdi-home",
          items: [],
        },
        {
          label: "L'équipe",
          url: "/equipe",
          icon: "mdi-account-group",
          items: [],
        },
        {
          label: "Activités",
          url: "/activites",
          icon: "mdi-party-popper",
          items: [],
        },
        {
          label: "Projets",
          url: "./",
          icon: "mdi-rocket-launch",
          items: [
            {
              label: "AnTAres",
              url: "/projets_en_cours/antares",
              subitems: [],
            },
            {
              label: "Perseus",
              url: "/projets_en_cours/perseus",
              subitems: [
                { 
                label: "Moteur",
                url: "/projets_en_cours/perseus/moteur"
                },
                {
                  label:"Capteurs",
                  url: "/projets_en_cours/perseus/capteurs"
                }],
            },
          ],
        },
        {
          label: "Galerie",
          url: "/galerie",
          icon: "mdi-image-filter-frames",
          items: [],
        },
        {
          label: "Contact",
          url: "/contact",
          icon: "mdi-email",
          items: [],
        },
      ],
    };
  },
  methods: {
    haveMultiItems(table) {
      return table.length == 0;
    },
  },
};
</script>

<style>

</style>